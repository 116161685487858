import React, { useState, useEffect } from 'react'
import L from 'leaflet'
import PropTypes from 'prop-types'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { Wrapper } from './ApdLeaflet-style'

export const ApdMap = ({
  center,
  zoom,
  maxZoom,
  minZoom,
  tileUrl,
  onViewportChanged,
  closePopupOnClick,
  children,
  showZoomControl
}) => {
  const [mapReady, setMapReady] = useState(false)

  useEffect(() => {
    // if (!mapReady) return null

    if (mapReady) {
      L.Map.include({
        getAllMarkersBy: function (key, find) {
          let markerArray = []
          this.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              const layerData = layer.options.icon.options.data
              const hasKeyProperty = Object.prototype.hasOwnProperty.call(
                layerData,
                key
              )
              if (hasKeyProperty && find === undefined) {
                markerArray = [...markerArray, layer]
                return
              }
              if (layerData[key] === find) {
                markerArray = [...markerArray, layer]
              }
            }
          })
          return markerArray
        }
      })
    }
  }, [mapReady])

  return (
    <Wrapper>
      <MapContainer
        onViewportChanged={onViewportChanged}
        center={center}
        zoom={zoom}
        whenReady={() => {
          setMapReady(true)
        }}
        attributionControl={false}
        maxZoom={maxZoom}
        minZoom={minZoom}
        zoomControl={false}
        closePopupOnClick={closePopupOnClick}
      >
        {/* SET TILE SERVER */}
        <TileLayer url={tileUrl} attribution={null} />
        {/* SHOW ZOOM CONTROL */}
        {showZoomControl && <ZoomControl position='bottomright' />}

        {children}
      </MapContainer>
    </Wrapper>
  )
}

ApdMap.propTypes = {
  center: PropTypes.array,
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  tileUrl: PropTypes.string,
  showZoomControl: PropTypes.bool
}

ApdMap.defaultProps = {
  center: [41.156608, -8.614529],
  showZoomControl: true,
  zoom: 14,
  maxZoom: 18,
  minZoom: 11,
  tileUrl: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
}
