const cmp = {
  t3: '#CFDBE8',
  t2: '#999FD9',
  t1: '#4D57BC',
  m : '#000F9F',
  s1: '#000C7F',
  s2: '#00095F',
  s3: '#000640',
}

const neutral = {
  // Neutral .gray
  gray_10: '#1A1A1A',
  gray_9 : '#262626',
  gray_8 : '#404040',
  gray_7 : '#595959',
  gray_6 : '#737373',
  gray_5 : '#8C8C8C',
  gray_4 : '#A6A6A6',
  gray_3 : '#BFBFBF',
  gray_2 : '#D9D9D9',
  gray_1 : '#F2F2F2',
  // Neutral bgray
  bgray_10: '#090E10',
  bgray_9 : '#1C2A33',
  bgray_8 : '#2E414D',
  bgray_7 : '#425866',
  bgray_6 : '#597080',
  bgray_5 : '#738A99',
  bgray_4 : '#8FA5B2',
  bgray_3 : '#ADC0CC',
  bgray_2 : '#CFDDE5',
  bgray_1 : '#F2FAFF',
}

export const APD = {
  colors: {
    // Brand color CMP
    cmp_t3: cmp.t3,
    cmp_t2: cmp.t2,
    cmp_t1: cmp.t1,
    cmp_m : cmp.m,
    cmp_s1: cmp.s1,
    cmp_s2: cmp.s2,
    cmp_s3: cmp.s3,
    // Brand color APD
    apd_t3: cmp.t3,
    apd_t2: cmp.t2,
    apd_t1: cmp.t1,
    apd_m : cmp.m,
    apd_s1: cmp.s1,
    apd_s2: cmp.s2,
    apd_s3: cmp.s3,
    // Neutral bgray
    bgray_10: neutral.bgray_10,
    bgray_9 : neutral.bgray_9,
    bgray_8 : neutral.bgray_8,
    bgray_7 : neutral.bgray_7,
    bgray_6 : neutral.bgray_6,
    bgray_5 : neutral.bgray_5,
    bgray_4 : neutral.bgray_4,
    bgray_3 : neutral.bgray_3,
    bgray_2 : neutral.bgray_2,
    bgray_1 : neutral.bgray_1,
    // Neutral .gray
    gray_10: neutral.gray_10,
    gray_9 : neutral.gray_9,
    gray_8 : neutral.gray_8,
    gray_7 : neutral.gray_7,
    gray_6 : neutral.gray_6,
    gray_5 : neutral.gray_5,
    gray_4 : neutral.gray_4,
    gray_3 : neutral.gray_3,
    gray_2 : neutral.gray_2,
    gray_1 : neutral.gray_1,
    // Base color
    base_white    : '#ffffff',
    base_dark_gray: neutral.gray_10,
  }
}