import React, { Fragment }  from 'react'

import {
    CssWrapper,
    CssAlighL,
    CssAlighD,
    CssIcon,
    CssTitle
} from './PageHeader-style'

export const PageHeader = ({ icon, pageTitle, children }) => {
    console.log(pageTitle)
    return (<>
        <CssWrapper>
            <CssAlighL>
                
                <CssTitle>
                    { icon && <CssIcon>{icon}</CssIcon>}
                    {pageTitle}
                </CssTitle>
                
            </CssAlighL>
            { children && <CssAlighD>
                {children}
            </CssAlighD>   }     
        </CssWrapper>
    </>)
}
