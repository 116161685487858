import styled from 'styled-components'
import { 
    T_H1,
    T_H4,
    T_H3 
} from '@apd/typography'

export const CssWrapper = styled.div`
    display: block;
`

export const CssCategory = styled(T_H4)`
    display: flex;
    color: ${({theme}) => theme.colors.text.category};
    margin-bottom: ${({theme}) => theme.size.spacing_3};
`
export const CssValue = styled(T_H1)`
 
    color: ${({theme}) => theme.colors.text.value};
`

export const CssMeasureType = styled(T_H3)`
    display: inline;
    line-height: 0;
    color: ${({theme}) => theme.colors.text.value};
`

export const CssLabel = styled(T_H3)`
    color: ${({theme}) => theme.colors.text.tag};
    line-height: ${({theme}) => theme.text.size.h3};
`
