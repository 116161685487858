import { GlobalToken } from './_GlobalToken'
import { GlobalSpace } from './_GlobalSpace'
import { APD as brandToken } from './_ApdDesignToken'

/**
 * Anatomy of a Design Token and Organization
 * 
 * Naming convention
 * [colors] [property] [container] [primary] [hover]
 * 
 * i.e. 
 * colors.bg.container.primary.hover
 * 
 */

export const LightTheme = {
	brandToken,
	...GlobalToken,
	colors: {
		// Text with color
		text: {
			default: brandToken.colors.gray_6,
			default_1: brandToken.colors.gray_8,
			default_2: brandToken.colors.base_dark_gray,
			title: brandToken.colors.base_white,
			value: brandToken.colors.gray_9,
			tag: brandToken.colors.gray_5,
			category: brandToken.colors.bgray_7,
			brand: brandToken.colors.apd_m,

			ipma   : {
				low       : "#28d761",
				low_1     : brandToken.colors.gray_8,
				medium    : "#ffc80a",
				medium_1  : brandToken.colors.gray_8,
				high      : "#fc7522",
				high_1    : brandToken.colors.gray_8,
				critical  : "#d00000",
				critical_1: brandToken.colors.gray_8,
				
				uv: {
					medium: "#ffc80a",
					high: "#fc7522",
					veryhigh: "#d00000",
					maximum: "#6041c1"
				}
			}
		},
		// Background color
		bg: {
			body:{
				default: brandToken.colors.gray_1, 
				info   : brandToken.colors.gray_1,
			},
			box: {
				default: brandToken.colors.base_white,
				info   : brandToken.colors.gray_1,
			},
			ipma: {
				low     : "#28d761",
				medium  : "#ffc80a",
				high    : "#fc7522",
				critical: "#d00000",
			},
			navbar: {
				default: brandToken.colors.base_white,
				logo: brandToken.colors.apd_m,
			}
		},
		// COLORS APPLIED TO THE BORDERS 
		border: {
			ipma: {
				cell: {
					default: brandToken.colors.gray_5,
					active : brandToken.colors.base_dark_gray
				}
			},
			navbar: {
				default: "#e5e5e5"
			}
		},
		// SVG colors
		svg:{
			default: brandToken.colors.gray_9,
		} 
	},
	...GlobalSpace,
}
