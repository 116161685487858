import styled from 'styled-components'


export const CssDropdownLabel = styled.div`
    font-size: 12px;
    text-align: left;
    padding: 4px 8px 0 8px;
    color: #425866;
    display: flex;
`

export const CssDropdownTitle = styled.div`
    margin: 15px 20px;
    color: #404040;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
`

export const CssMenuContainer = styled.div.attrs(props => ({
    className: props.className
}))`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-flow: column-nowrap;
    font-family: Roboto;

    & .menu-trigger {
        display: flex;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
        width: 100%;
        border: none;
        border-bottom: 1px #425866 solid;
        flex-wrap: nowrap;
        padding: 4px 8px 0 8px;
        outline: none;
        min-width: 200px;
    }

    & .menu-title {
        color: #000F9F;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
    }

    &   .menu-arrow {
        flex: 1;
        vertical-align: middle;
        width: 40px;
        font-size: 14px;
        margin: 0;
        text-align: right;
    }

    & .menu {
        background: #ffffff;
        border-radius: 0 0 8px 8px;
        position: absolute;
        z-index: ${({theme}) => theme.zIndex.postbox};
        top: 54px;
        right: 0;
        min-width: 200px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: 
            opacity ${({theme}) => theme.time.duration_promptly}s ease, 
            transform ${({theme}) => theme.time.duration_promptly}s ease, 
            visibility ${({theme}) => theme.time.duration_promptly}s;

        & ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        & li {
            border-bottom: 1px solid #dddddd;
            margin-bottom: 4px;

            & button {
                cursor: pointer;
                background-color: transparent;
                text-align: left;
                text-decoration: none;
                padding: 15px 20px;
                color: #333333;
                display: block;
                width: 100%;
                border: 0;
                font-size: 14px;
                line-height: 14px;

                &:hover,
                &:focus {
                    text-decoration: none
                }
                
                &.active {
                    color: #000C7F;
                    font-weight: 700;
                }
            }
        }
    }

    & .menu.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    } 


`
