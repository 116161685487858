import styled from 'styled-components'
import { T_Body2 } from '@apd/typography'


export const CssWrapper = styled.div`
    background-color: ${ ({ theme }) => theme.colors.bg.navbar.default };
    padding: 0 ${ ({ theme }) => theme.size.spacing_6 };
    display: flex;
    height: ${ ({ theme }) => theme.size.layout_2 };
    justify-content: space-between;
    border-top: 1px ${ ({ theme }) => theme.colors.bg.body.default } solid;
`

export const CssAppInfo = styled(T_Body2)`
    color: ${ ({ theme }) => theme.colors.text.brand };
`
export const CssAppOwner = styled(T_Body2)`
    color: ${ ({ theme }) => theme.colors.text.brand };
`

export const CssAppNameWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const CssLogoWrapper = styled.div`
    display: flex;
    max-width: ${ ({ theme }) => 
        parseInt(theme.size.layout_7) }px;
    justify-content: center;
    flex-direction: column;
`