import styled from 'styled-components'

export const CssWrapper = styled.div`
    font-family: 'Roboto';
    padding: ${({ theme }) => theme.size.spacing_6};
    background-color: ${({ theme }) => theme.colors.bg.box.default};
    border-radius: ${({ theme }) => theme.radius.medium};
    flex-direction: column;
    display: flex;
    font-size: ${ ({ theme }) => theme.text.size.body};
    font-weight: ${ ({ theme }) => theme.text.weight.regular};
    line-height: ${ ({ theme }) => theme.text.line_height.body };
`

export const CssHeader = styled.div`
    color: ${ ({ theme }) => theme.colors.text.default_2 };
    font-size: ${ ({ theme }) => theme.text.size.h3};
    font-weight: ${ ({ theme }) => theme.text.weight.bold};
    line-height: ${ ({ theme }) => theme.text.line_height.h3 };
    margin-top: 0;
    margin-bottom: ${ ({ theme }) => theme.size.spacing_7 };
`

export const CssBody = styled.div`
    
`