import React from 'react'

import { CssWrapper, CssHeader, CssBody } from './Tile-style'

/**
 * 
 * MAIN WRAPPER 
 */
export const Tile = ({ header, children }) => (
    <CssWrapper>
        { header && <Header>{header}</Header> }
        <CssBody>{children}</CssBody>
    </CssWrapper>
)

/**
 * 
 * HEADER WRAPPER
 */
const Header = ({children}) => (
    <CssHeader>
        {children}
    </CssHeader>
)
