import styled from 'styled-components'
import { T_H3 } from '@apd/typography'

export const CssAlighL = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const CssTitle = styled(T_H3)`
    color: ${ ({ theme }) => theme.colors.text.brand }; 
    font-weight: ${ ({ theme }) => theme.text.weight.bold };
    margin-top: ${ ({ theme }) => theme.size.spacing_5 };
`

export const CssIcon = styled.div`
    display: inline;
    margin-right: ${ ({ theme }) => theme.size.spacing_3 };
`

export const CssAlighD = styled.div`
    margin-top: ${ ({ theme }) => theme.size.spacing_1 };
    display: flex;
    justify-content: flex-end;
`
export const CssWrapper = styled.div`
    height: ${ ({ theme }) => theme.size.layout_6 };
    display: flex;
    justify-content: space-between;

`
