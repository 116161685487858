import styled from 'styled-components'

export const T_H1 = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1};
    font-size: ${ ({ theme }) => theme.text.size.h1};
    line-height: ${ ({ theme }) => theme.text.line_height.h1 };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`
export const T_H2 = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1 };
    font-size: ${ ({ theme }) => theme.text.size.h2 };
    line-height: ${ ({ theme }) => theme.text.line_height.h2 };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`

export const T_H3 = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1 };
    font-size: ${ ({ theme }) => theme.text.size.h3 };
    line-height: ${ ({ theme }) => theme.text.line_height.h3 };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`

export const T_H4 = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1 };
    font-size: ${ ({ theme }) => theme.text.size.h4 };
    line-height: ${ ({ theme }) => theme.text.line_height.h4 };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`
export const T_Body = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1 };
    font-size: ${ ({ theme }) => theme.text.size.body };
    line-height: ${ ({ theme }) => theme.text.line_height.body };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`
export const T_Body2 = styled.div`
    font-family: 'Roboto';
    color: ${({ theme }) => theme.colors.text.default_1 };
    font-size: ${ ({ theme }) => theme.text.size.body2 };
    line-height: ${ ({ theme }) => theme.text.line_height.body2 };
    font-weight: ${ ({ theme, weight }) => {
        const w = weight || "regular"
        return theme.text.weight[w]}
    };
`