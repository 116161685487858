import React from 'react'
import PropTypes from 'prop-types';

import { 
    CssWrapper,
    CssCategory,
    CssValue,
    CssLabel,
    CssMeasureType
} from './Kpi-styles'

export const Kpi = ({ category, value, measureType, label }) => (
    <CssWrapper>
        { category && <CssCategory>{category}</CssCategory>}
        <Value measureType={measureType}>{value}</Value>
        { label && <CssLabel>{label}</CssLabel>}
    </CssWrapper>
)

const Value = ({children, measureType}) => (
    <CssValue weight="bold" >
        
        {children}
        
        { measureType && <CssMeasureType weight="bold">
            {measureType}
        </CssMeasureType>}
    </CssValue>
)

Kpi.propTypes = {
    category: PropTypes.node,
    value: PropTypes.node,
    measureType: PropTypes.node,
    label: PropTypes.node,
}