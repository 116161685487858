import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'; 

function MediaSvg({name, fill}) {

	/* Use state hook to store icon module value */
	const [iconModule, setIconModule] = useState(null)

	useEffect(() => {
		/* Use dynamic import to get corresponding icon as a module */
		import(`!!@svgr/webpack?-svgo,+titleProp,+ref!./media-svg/${name}.svg`)
			.then((e) => {
				/* Persist data in state */
				setIconModule(e)
			})
			.catch((error) => {
				/* Do not forget to handle errors */
				console.error(`Icon with name: ${name} not found! ${error}`)
			});
	}, [name /* update on name change */])

	const renderIcon = () => {
		if (!iconModule) return null
		/* Equal to: import { ReactComponent as Icon } from "icon.svg" */
		const Component = iconModule.default

		return <Component fill={fill || "#000000"} />
	};

	return renderIcon()
}

export { MediaSvg }

MediaSvg.propTypes = {
	name: PropTypes.string.isRequired,
	fill: PropTypes.string
}

MediaSvg.defaultProps = {
	name: 'logo_apd',
	fill: '#000000'
}