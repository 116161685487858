/* ===================== 
//     Spatial units
//
//     Spatial tokens are used for padding, margins, and position coordinates. 
//     Border-width tokens are used for the border property.
// ===================== */
const spatial = {
	/* spacing */
	spacing_1: "2px",
	spacing_2: "4px",
	spacing_3: "8px",
	spacing_4: "12px",
	spacing_5: "16px",
	spacing_6: "24px",
	spacing_7: "32px",
	spacing_8: "40px",
	spacing_9: "48px",
	/* Layout */
	layout_1: "16px",
	layout_2: "24px",
	layout_3: "32px",
	layout_4: "48px",
	layout_5: "56px",
	layout_6: "64px",
	layout_7: "96px",
	layout_8: "160px",
	layout_9: "256px",
	layout_10: "416px",
	layout_11: "672px",
}

export const GlobalSpace = {
	size: {
		/* ===================== 
		//     Spatial units
		//
		//     Spatial tokens are used for padding, margins, and position coordinates. 
		//     Border-width tokens are used for the border property.
		// ===================== */
		/* spacing */
		...spatial,
		// TOOLTIP
		tooltip: {
			small: spatial.layout_7, // 80
			medium: spatial.layout_8, //GlobalSpace.size.layout_8, // 240,
			large: spatial.layout_9, //GlobalSpace.size.layout_9 // 320,
		},
		// MODAL
		modal: {
			small: spatial.layout_9, // GlobalSpace.size.layout_9, // 300,
			medium: spatial.layout_10,// GlobalSpace.size.layout_10, // 450,
			large: spatial.layout_11 // GlobalSpace.size.layout_10 // 600,
		}
	}
}