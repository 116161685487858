export const GlobalToken = {
	shadow: {
		/* =====================
		//      Shadow
		//
		//      Use shadow tokens to set a box shadow.
		//      Need to review this 
		// ===================== */
		near: "0px 2px 4px", // [0, 2, 4], // rgba(173, 192, 204, 0.8),
		far: "0px 4px 8px", // [0, 4, 8], // 0px 4px 8px rgba(173, 192, 204, 0.7),
		flying: "0px 8px 16px" //[0, 8, 16] // 0px 8px 16px rgba(173, 192, 204, 0.6),
	},
	radius: {
		/* =====================
		//     Radius
		//
		//     Use radius tokens to change the border-radius size (rounded corners).
		// ===================== */
		small: "4px",
		medium: "8px",
		large: "12px",
		xlarge: "px"
	},
	time: {
		/* =====================
		//     Time
		//
		//     Use timing tokens for animation durations.
		// ===================== */
		duration_instantly: 0,
		duration_immediately: 0.05,
		duration_quickly: 0.1,
		duration_promptly: 0.2,
		duration_slowly: 0.4,
		duration_superslowly: 2,
		duration_paused: 3.2
	},
	mquery: {
		/* ===============================================================
		//     Media Query
		//
		//     Use media query tokens to set media query width ranges.
		//      // only screen and (min-width: {value}px),
		// =============================================================== */
		large: "1020px",
		medium: "1440px",
		small: "1024px"
	},
	zIndex: {
		/* ===============================================================
		//     Z-index
		//
		//     Use z-index tokens to set the z order layering of elements.
		// =============================================================== */
		satellite: 5000,
		skyscraper: 1000,
		tower_block: 500,
		house: 200,
		bungalow_chimney: 110,
		bungalow: 100, // navbar
		shed: 50,
		postbox: 10,
		curb: 1,
		pavement: 0, // body
		pothole: -10,
		ditch: -20,
		sewer: -100,
		mine: -300,
		seabed: -1000
	},
	text: {
		weight:{
			light  : 200,
			regular: 400,
			bold   : 700,
		},
		size: {
			h1   : "33px",
			h2   : "25px",
			h3   : "19px",
			h4   : "14px",
			body : "14px",
			body1: "14px",
			body2: "12px"
		},
		line_height: {
			h1   : "40px",
			h2   : "40px",
			h3   : "32px",
			h4   : "24px",
			body : "24px",
			body1: "24px",
			body2: "16px"
		}
	}
}

