import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { CssMenuContainer, CssDropdownLabel, CssDropdownTitle } from './Dropdown-style'
import { useDetectOutsideClick } from "./useDetectOutsideClick"

//icons
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

export const Dropdown = ({ dropdownTitle, labelInfo, list, onEvent }) => {
    const dropdownRef = useRef()
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const [selectedValue, setSelectedValue] = useState(null)

    const onClick = () => setIsActive(!isActive)

    const onSelect = eSel => {
        const v = JSON.parse(eSel.currentTarget.value)
        setIsActive(false)
        setSelectedValue(v)
    }

    useEffect(() => {
        if (!list || list.length < 1) return null

        if (selectedValue === null) setSelectedValue(list[0])
    }, [list])

    useEffect(() => {
        selectedValue && onEvent(selectedValue)
    }, [selectedValue])

    return (
        <CssMenuContainer ref={dropdownRef} >
            <CssDropdownLabel>{labelInfo}</CssDropdownLabel>
            <button onClick={onClick} className="menu-trigger">
                <p className="menu-title">
                    {selectedValue && selectedValue.description}
                </p>
                <p className="menu-arrow" > 
                    {isActive ? <ArrowDropUp /> : <ArrowDropDown />} 
                </p>
            </button>
            <div
                
                className={`menu ${isActive ? "active" : "inactive"}`}
            >
                {dropdownTitle && <CssDropdownTitle>{dropdownTitle}</CssDropdownTitle>}

                <ul>
                    {list && list.map((item, index) => (
                        <li key={index}>
                            <button
                                key={item.value}
                                className={`${(selectedValue && selectedValue.value) === item.value ? "active" : "inactive"}`}
                                onClick={onSelect}
                                value={JSON.stringify(item)} >
                                {item.description}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

        </CssMenuContainer>
    )
}

Dropdown.propTypes = {
    labelInfo: PropTypes.string,
    dropdownTitle: PropTypes.string,
    list: PropTypes.array.isRequired,
    onEvent: PropTypes.func
}

Dropdown.defaultProps = {

}