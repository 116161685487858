import { GlobalToken } from './_GlobalToken'
import { GlobalSpace } from './_GlobalSpace'
import { APD as brandToken } from './_ApdDesignToken'

/**
 * Anatomy of a Design Token and Organization
 * 
 * Naming convention
 * [colors] [property] [container] [primary] [hover]
 * 
 * i.e. 
 * colors.bg.container.primary.hover
 * 
 */

export const DarkTheme = {
	brandToken,
	...GlobalToken,
	colors: {
		// Text with color
		text: {
			default: brandToken.colors.gray_6,
			default_1: brandToken.colors.gray_4,
			default_2: brandToken.colors.base_white,
			title: brandToken.colors.base_white,
			value: brandToken.colors.base_white,
			tag: brandToken.colors.gray_5,
			category: brandToken.colors.bgray_4,
			brand: brandToken.colors.apd_m, // #000F9F

			ipma: {
				low       : "#2c9c47",
				low_1     : brandToken.colors.gray_8,
				medium    : "#ffc80a",
				medium_1  : brandToken.colors.gray_8,
				high      : "#fc7522",
				high_1    : brandToken.colors.gray_8,
				critical  : "#d00000",
				critical_1: brandToken.colors.gray_8,
				
				uv: {
					medium: "#ffc80a",
					high: "#fc7522",
					veryhigh: "#d00000",
					maximum: "#6041c1"
				}
			}
		},
		// Background color
		bg: {
			body:{
				default: '#000000',
				info   : brandToken.colors.gray_1,
			},
			box: { 
				default: brandToken.colors.bgray_9,
				info   : brandToken.colors.bgray_9,
			},
			ipma: {
				low: "#2c9c47",
				medium: "#ffc80a",
				high: "#fc7522",
				critical: "#d00000",
			},
			navbar: {
				default: brandToken.colors.bgray_9,
				logo: brandToken.colors.base_white
			}
		},
		// COLORS APPLIED TO THE BORDERS 
		border: {
			ipma: {
				cell: {
					default: brandToken.colors.gray_8,
					active: brandToken.colors.base_white
				}
			},
			navbar: {
				default: brandToken.colors.base_dark_gray,
			}
		},
		// SVG colors
		svg:{
			default: brandToken.colors.gray_1,
		} 
	},
	...GlobalSpace,
}
