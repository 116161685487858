import React from 'react'
import PropTypes from 'prop-types'
import { MediaSvg } from '@apd/media-svg'

import { 
    CssWrapper, 
    CssAppNameWrapper, 
    CssLogoWrapper,
    CssAppInfo,
    CssAppOwner } from './Footer-style'

export const Footer = ({ appName, appVersion }) => (
    <CssWrapper>
        <CssAppNameWrapper>
            <CssAppInfo weight="regular">{appName} - v{appVersion}</CssAppInfo>
        </CssAppNameWrapper>

        <CssLogoWrapper>
            {/* <MediaSvg name="logo_apd" /> */}
            <CssAppOwner weight="regular">Porto Digital</CssAppOwner>
        </CssLogoWrapper>
    </CssWrapper>
)